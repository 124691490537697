import React, { useState } from "react";
import "./AppointmentScheduler.css";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";
import { Spinner } from "reactstrap";

import { Row, Col, Label, Input, FormGroup, Form, Button } from "reactstrap";

const AppointmentScheduler = () => {
  //#region Declaraciones
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [pet, setPet] = useState("");
  const [nameLandlord, setNameLandlord] = useState("");
  const [phone, setPhone] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [botonDesactivado, setBotonDesactivado] = useState(false);

  const [loading, setLoading] = useState(false);

  const url = "https://appointments.somee.com/api/Appointments";
  // const url = "http://localhost:5239/api/Appointments";
  //#endregion

  //#region Funciones
  function ValidaAntesDeGuardar() {
    if (nameLandlord.trim() === "") {
      setErrorMessage("Por favor, ingrese el nombre del propietario");
      return false;
    }
    const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (!regex.test(phone)) {
      setErrorMessage("Por favor, ingrese un numero de telefono a 10 digitos");
      return false;
    }

    if (pet.trim() === "") {
      setErrorMessage("Por favor, ingrese el nombre de la mascota");
      return false;
    }

    const currentDate = new Date();
    if (selectedDate < formatDate(currentDate)) {
      setErrorMessage("Por favor, selecciona una fecha en el futuro.");
      return false;
    }

    if (selectedTime.trim() === "") {
      setErrorMessage("Por favor, seleccione la hora de la cita");
      return false;
    }

    setErrorMessage("");
    return true;
  }

  const mostrarAlertaOK = () => {
    Swal.fire({
      title: "Éxito",
      text: "¡Cita agendada correctamente!",
      icon: "success",
      timer: 4000,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "https://vetalexia.com/";
        return;
      }
    });

    setTimeout(() => {
      window.location.href = "https://vetalexia.com/";
    }, 4000);
  };

  const mostrarAlertaError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Lo siento, hubo un problema al guardar la cita. Por favor, inténtalo de nuevo más tarde",
      timer: 4000,
    });
  };

  const activarBoton = () => {
    setBotonDesactivado(false);
  };

  const register_Appointment = () => {
    if (ValidaAntesDeGuardar() === false) return;

    const currentDate = new Date();

    const data = {
      pet: pet.trim(),
      date: selectedDate,
      time: formatDate(currentDate) + "T" + selectedTime + ":00.000Z",
      nameLandlord: nameLandlord,
      phone: phone,
    };
    setBotonDesactivado(true);
    setLoading(true);
    axios
      .post(url, data)
      .then((response) => {
        if (response.status !== 201) {
          throw new Error("La solicitud no fue exitosa");
        } else {
          mostrarAlertaOK();
        }
      })
      .catch((error) => {
        console.error("Error sending data: ", error);
        mostrarAlertaError();
        activarBoton();
        setLoading(false);
      });
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11, por eso se suma 1
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  //#endregion

  //#region  Eventos
  const handleChangeNameLandlord = (e) => {
    e.preventDefault();
    setNameLandlord(e.target.value);

    if (e.target.value.trim() === "") {
      setErrorMessage("Por favor, ingrese el nombre del propietario");
      return;
    } else {
      setErrorMessage("");
    }
  };

  const handleChangePhone = (e) => {
    e.preventDefault();
    setPhone(e.target.value);

    if (e.target.value.trim() === "") {
      setErrorMessage("Por favor, ingrese un numero de telefono a 10 digitos");
      return;
    } else {
      setErrorMessage("");
    }
  };

  //Text Change pet
  const handleChange = (e) => {
    e.preventDefault();
    setPet(e.target.value);

    if (e.target.value.trim() === "") {
      setErrorMessage("Por favor, ingrese el nombre de la mascota");
      return;
    } else {
      setErrorMessage("");
    }
  };
  //Text Change date
  const handleDateChange = (e) => {
    e.preventDefault();
    setSelectedDate(e.target.value);
    const currentDate = new Date();
    if (e.target.value < formatDate(currentDate)) {
      setErrorMessage("Por favor, selecciona una fecha en el futuro.");
      return;
    } else {
      setErrorMessage("");
    }
  };
  //Text Change time
  const handleTimeChange = (e) => {
    e.preventDefault();
    setSelectedTime(e.target.value);
    if (e.target.value.trim() === "") {
      setErrorMessage("Por favor, seleccione la hora de la cita");
      return;
    } else {
      setErrorMessage("");
    }
  };
  //Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    register_Appointment();
  };
  //#endregion

  return (
    <div className="scheduler-container">
      <header>
        <img
          src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=329,h=117,fit=crop,trim=8.852459016393443;0;8.852459016393443;0/mP4NePxxEXHrL5RO/logo_alexia_portada-mv0l1Za9vVUzwKPq.png"
          alt="VetAlexiaLogo"
        ></img>
      </header>
      <br />
      <h2>Agendar Cita</h2>

      <Form className="scheduler-form" onSubmit={handleSubmit}>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="name">Nombre de propietario:</Label>
              <Input
                name="landlord"
                // placeholder="Juan Pérez"
                type="text"
                value={nameLandlord}
                onChange={handleChangeNameLandlord}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="name">Número de teléfono:</Label>
              <Input
                name="phone"
                // placeholder="(123) 456-7890"
                type="text"
                value={phone}
                onChange={handleChangePhone}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="name">Nombre de la mascota:</Label>
              <Input
                name="pet"
                id="pet"
                value={pet}
                onChange={handleChange}
                // placeholder="Beili"
                type="text"
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="name">Fecha:</Label>
              <Input
                name="date"
                id="date"
                value={selectedDate}
                onChange={handleDateChange}
                type="date"
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="name">Hora:</Label>
              <Input
                name="time"
                id="time"
                value={selectedTime}
                onChange={handleTimeChange}
                type="time"
                required
              />
            </FormGroup>
          </Col>
        </Row>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <Button disabled={botonDesactivado} type="submit">
          Agendar
        </Button>{" "}
        <div className="spinner-container">
          {loading ? <Spinner color="success" /> : <div></div>}
        </div>
      </Form>
    </div>
  );
};

export default AppointmentScheduler;
