import "./App.css";
import AppointmentScheduler from "./components/AppointmentScheduler";

function App() {
  return (
    <div>
      <AppointmentScheduler />
    </div>
  );
}

export default App;
